<script setup lang="ts">
import { ChevronRightIcon, BookmarkIcon, MagnifyingGlassIcon, ArrowLongRightIcon } from '@heroicons/vue/24/outline';
// From the Nuxt error page docs: https://nuxt.com/docs/getting-started/error-handling#error-page
interface NuxtError {
  url: string;
  statusCode: number;
  statusMessage: string;
  message: string;
  description: string;
  data: any;
  stack?: string;
}

const props = defineProps<{
  error: Error | NuxtError;
}>();

const { t } = useI18n();
const route = useRoute();

function isNuxtError(error: Error | NuxtError): error is NuxtError {
  return (error as NuxtError).statusCode !== undefined;
}

const errorCode = computed(() => {
  if (isNuxtError(props.error)) {
    return props.error.statusCode;
  }

  // Default to assuming it is a client side error - Nuxt server errors will throw a 5xx error code.
  return 400;
});

const title = computed(() => {
  if (errorCode.value === 404) {
    return t('pages.error.meta.notFoundTitle');
  }

  return t('pages.error.meta.errorTitle');
});

const apology = computed(() => {
  if (errorCode.value === 404) {
    return t('pages.error.notFoundApology');
  }

  return t('pages.error.errorApology');
});

useAppSeoMeta({
  title,
});

const links = computed(() => [
  {
    heading: t('pages.error.explore.heading'),
    description: t('pages.error.explore.description'),
    path: '/explore',
    icon: BookmarkIcon,
  },
  {
    heading: t('pages.error.search.heading'),
    description: t('pages.error.search.description'),
    path: '/search',
    icon: MagnifyingGlassIcon,
  },
]);

const notFoundUrl = computed(() => {
  let val = import.meta.client ? window.location.href : '';
  // The `redirected` value in the query string indicates that a locale prefix was prepended, in an attempt
  // to resolve a language-free URL
  if (route.query.redirected) {
    // Because the attempted resolution failed, the original bad URL is now reconstructed here:
    // - the prepended locale prefix is removed by `slice(3)`
    // - the `redirected` value is deleted from the query string
    const origin = import.meta.client ? window.location.origin : '';
    const reconstructedUrl = new URL(`${origin}${route.fullPath.slice(3)}`);
    reconstructedUrl.searchParams.delete('redirected');
    val = reconstructedUrl.toString();
  }

  return val;
});

async function handleError(path: string): Promise<void> {
  await clearError({ redirect: path });
}
</script>

<template>
  <div>
    <NuxtLayout>
      <main class="error-page page-wrapper container">
        <div class="flex-shrink-0">
          <img
            class="mx-auto h-16 w-auto"
            src="https://res.cloudinary.com/db8eidwqd/image/upload/v1623639498/WASH%20Resources/Logos/cawst_logo--drop_uvyiik.jpg"
            alt="Workflow"
          />
        </div>
        <div class="page-subsection">
          <div class="text-center">
            <p class="h2--no-border mb-0">{{ t('pages.error.errorCode', { errorCode }) }}</p>
            <h1 class="h1 mb-2">
              <template v-if="errorCode === 404">
                {{ t('pages.error.header.plainText') }}
                <span class="highlighted-text">{{ t('pages.error.header.highlightedText') }}</span>
              </template>
              <template v-else>
                {{ t('pages.error.header.errorText') }}
              </template>
            </h1>

            <ClientOnly>
              <!-- TODO: Display URL that was not found -->
              <!-- notFoundUrl wants to uses window.location. Enforcing client only avoid hydration errors getting logged -->
              <p v-if="notFoundUrl" class="my-9 text-xl text-red-600">{{ notFoundUrl }}</p>
            </ClientOnly>
            <p class="text-lg">{{ apology }}</p>
            <button
              v-if="errorCode !== 404"
              class="btn btn-primary mt-7 inline-block"
              @click="handleError(route.fullPath)"
            >
              {{ t('pages.error.reloadPage') }}
            </button>
          </div>
          <div class="mx-auto max-w-xl py-9 sm:py-9">
            <h2 class="h2--no-border pb-0">{{ t('pages.error.popular') }}</h2>
            <ul role="list" class="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
              <li v-for="(link, index) in links" :key="index" class="relative flex items-start space-x-4 py-6">
                <div class="flex-shrink-0">
                  <span class="flex h-12 w-12 items-center justify-center rounded-lg bg-grey-light text-gray-400">
                    <component :is="link.icon" class="h-6 w-6 stroke-2" />
                  </span>
                </div>
                <div class="min-w-0 flex-1">
                  <h3 class="text-base font-medium text-gray-900">
                    <span
                      class="rounded-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2"
                    >
                      <button @click="handleError(link.path)">
                        <span class="absolute inset-0" aria-hidden="true" />
                        {{ link.heading }}
                      </button>
                    </span>
                  </h3>
                  <p class="text-base text-gray-500">{{ link.description }}</p>
                </div>
                <div class="flex-shrink-0 self-center">
                  <ChevronRightIcon class="h-5 w-5 stroke-2 text-gray-400" />
                </div>
              </li>
            </ul>
            <div class="mt-8">
              <button class="font-semibold" @click="handleError('/')">
                {{ t('pages.error.homepage') }}<ArrowLongRightIcon class="mb-0.5 ml-2 inline-block h-4 stroke-2" />
              </button>
            </div>
          </div>
        </div>

        
      </main>
    </NuxtLayout>
  </div>
</template>
