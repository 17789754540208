import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91legacySlug_93hzZqxL4f4IMeta } from "/vercel/path0/pages/[legacyTypeSlug]/[legacySlugId]/[legacySlug].vue?macro=true";
import { default as _91_46_46_46maybeIdSlug_93r8yjuUqxhsMeta } from "/vercel/path0/pages/collections/[...maybeIdSlug].vue?macro=true";
import { default as _91slug_93Qd5Zg6s0JiMeta } from "/vercel/path0/pages/collections/[idSlug]/[slug].vue?macro=true";
import { default as explore2KSB1Dnfk8Meta } from "/vercel/path0/pages/explore.vue?macro=true";
import { default as _91slug_93CHlAcrjUxNMeta } from "/vercel/path0/pages/module/[idSlug]/[slug].vue?macro=true";
import { default as _91_46_46_46maybeIdSlug_93SYrmYkVStoMeta } from "/vercel/path0/pages/organizations/[...maybeIdSlug].vue?macro=true";
import { default as _91slug_93JLfjcO4Z6xMeta } from "/vercel/path0/pages/organizations/[idSlug]/[slug].vue?macro=true";
import { default as _91_46_46_46maybeSlugIdAndSlug_93VB8rjY3YlOMeta } from "/vercel/path0/pages/redirect/[category]/[...maybeSlugIdAndSlug].vue?macro=true";
import { default as _91_46_46_46maybeIdSlug_93NccstdHv9YMeta } from "/vercel/path0/pages/resources/[...maybeIdSlug].vue?macro=true";
import { default as _91slug_93AKBUeXLpCbMeta } from "/vercel/path0/pages/resources/[idSlug]/[slug].vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as _91_46_46_46maybeIdSlug_93Ltuj4XOI1hMeta } from "/vercel/path0/pages/topics/[...maybeIdSlug].vue?macro=true";
import { default as _91slug_93QpkuWdxVBdMeta } from "/vercel/path0/pages/topics/[idSlug]/[slug].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legacyTypeSlug-legacySlugId-legacySlug___en",
    path: "/en/:legacyTypeSlug()/:legacySlugId()/:legacySlug()",
    meta: _91legacySlug_93hzZqxL4f4IMeta || {},
    component: () => import("/vercel/path0/pages/[legacyTypeSlug]/[legacySlugId]/[legacySlug].vue").then(m => m.default || m)
  },
  {
    name: "legacyTypeSlug-legacySlugId-legacySlug___es",
    path: "/es/:legacyTypeSlug()/:legacySlugId()/:legacySlug()",
    meta: _91legacySlug_93hzZqxL4f4IMeta || {},
    component: () => import("/vercel/path0/pages/[legacyTypeSlug]/[legacySlugId]/[legacySlug].vue").then(m => m.default || m)
  },
  {
    name: "legacyTypeSlug-legacySlugId-legacySlug___fr",
    path: "/fr/:legacyTypeSlug()/:legacySlugId()/:legacySlug()",
    meta: _91legacySlug_93hzZqxL4f4IMeta || {},
    component: () => import("/vercel/path0/pages/[legacyTypeSlug]/[legacySlugId]/[legacySlug].vue").then(m => m.default || m)
  },
  {
    name: "collections-maybeIdSlug___en",
    path: "/en/collections/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93r8yjuUqxhsMeta || {},
    component: () => import("/vercel/path0/pages/collections/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "collections-maybeIdSlug___es",
    path: "/es/collections/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93r8yjuUqxhsMeta || {},
    component: () => import("/vercel/path0/pages/collections/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "collections-maybeIdSlug___fr",
    path: "/fr/collections/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93r8yjuUqxhsMeta || {},
    component: () => import("/vercel/path0/pages/collections/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "collections-idSlug-slug___en",
    path: "/en/collections/:idSlug()/:slug()",
    meta: _91slug_93Qd5Zg6s0JiMeta || {},
    component: () => import("/vercel/path0/pages/collections/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-idSlug-slug___es",
    path: "/es/collections/:idSlug()/:slug()",
    meta: _91slug_93Qd5Zg6s0JiMeta || {},
    component: () => import("/vercel/path0/pages/collections/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-idSlug-slug___fr",
    path: "/fr/collections/:idSlug()/:slug()",
    meta: _91slug_93Qd5Zg6s0JiMeta || {},
    component: () => import("/vercel/path0/pages/collections/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "explore___en",
    path: "/en/explore",
    meta: explore2KSB1Dnfk8Meta || {},
    component: () => import("/vercel/path0/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "explore___es",
    path: "/es/explore",
    meta: explore2KSB1Dnfk8Meta || {},
    component: () => import("/vercel/path0/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "explore___fr",
    path: "/fr/explore",
    meta: explore2KSB1Dnfk8Meta || {},
    component: () => import("/vercel/path0/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "module-idSlug-slug___en",
    path: "/en/module/:idSlug()/:slug()",
    component: () => import("/vercel/path0/pages/module/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "module-idSlug-slug___es",
    path: "/es/module/:idSlug()/:slug()",
    component: () => import("/vercel/path0/pages/module/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "module-idSlug-slug___fr",
    path: "/fr/module/:idSlug()/:slug()",
    component: () => import("/vercel/path0/pages/module/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organizations-maybeIdSlug___en",
    path: "/en/organizations/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93SYrmYkVStoMeta || {},
    component: () => import("/vercel/path0/pages/organizations/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "organizations-maybeIdSlug___es",
    path: "/es/organizations/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93SYrmYkVStoMeta || {},
    component: () => import("/vercel/path0/pages/organizations/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "organizations-maybeIdSlug___fr",
    path: "/fr/organizations/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93SYrmYkVStoMeta || {},
    component: () => import("/vercel/path0/pages/organizations/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "organizations-idSlug-slug___en",
    path: "/en/organizations/:idSlug()/:slug()",
    meta: _91slug_93JLfjcO4Z6xMeta || {},
    component: () => import("/vercel/path0/pages/organizations/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organizations-idSlug-slug___es",
    path: "/es/organizations/:idSlug()/:slug()",
    meta: _91slug_93JLfjcO4Z6xMeta || {},
    component: () => import("/vercel/path0/pages/organizations/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organizations-idSlug-slug___fr",
    path: "/fr/organizations/:idSlug()/:slug()",
    meta: _91slug_93JLfjcO4Z6xMeta || {},
    component: () => import("/vercel/path0/pages/organizations/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "redirect-category-maybeSlugIdAndSlug___en",
    path: "/en/redirect/:category()/:maybeSlugIdAndSlug(.*)*",
    meta: _91_46_46_46maybeSlugIdAndSlug_93VB8rjY3YlOMeta || {},
    component: () => import("/vercel/path0/pages/redirect/[category]/[...maybeSlugIdAndSlug].vue").then(m => m.default || m)
  },
  {
    name: "redirect-category-maybeSlugIdAndSlug___es",
    path: "/es/redirect/:category()/:maybeSlugIdAndSlug(.*)*",
    meta: _91_46_46_46maybeSlugIdAndSlug_93VB8rjY3YlOMeta || {},
    component: () => import("/vercel/path0/pages/redirect/[category]/[...maybeSlugIdAndSlug].vue").then(m => m.default || m)
  },
  {
    name: "redirect-category-maybeSlugIdAndSlug___fr",
    path: "/fr/redirect/:category()/:maybeSlugIdAndSlug(.*)*",
    meta: _91_46_46_46maybeSlugIdAndSlug_93VB8rjY3YlOMeta || {},
    component: () => import("/vercel/path0/pages/redirect/[category]/[...maybeSlugIdAndSlug].vue").then(m => m.default || m)
  },
  {
    name: "resources-maybeIdSlug___en",
    path: "/en/resources/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93NccstdHv9YMeta || {},
    component: () => import("/vercel/path0/pages/resources/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "resources-maybeIdSlug___es",
    path: "/es/resources/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93NccstdHv9YMeta || {},
    component: () => import("/vercel/path0/pages/resources/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "resources-maybeIdSlug___fr",
    path: "/fr/resources/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93NccstdHv9YMeta || {},
    component: () => import("/vercel/path0/pages/resources/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "resources-idSlug-slug___en",
    path: "/en/resources/:idSlug()/:slug()",
    meta: _91slug_93AKBUeXLpCbMeta || {},
    component: () => import("/vercel/path0/pages/resources/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-idSlug-slug___es",
    path: "/es/resources/:idSlug()/:slug()",
    meta: _91slug_93AKBUeXLpCbMeta || {},
    component: () => import("/vercel/path0/pages/resources/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "resources-idSlug-slug___fr",
    path: "/fr/resources/:idSlug()/:slug()",
    meta: _91slug_93AKBUeXLpCbMeta || {},
    component: () => import("/vercel/path0/pages/resources/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchBIFC2dE21WMeta || {},
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: searchBIFC2dE21WMeta || {},
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: searchBIFC2dE21WMeta || {},
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "topics-maybeIdSlug___en",
    path: "/en/topics/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93Ltuj4XOI1hMeta || {},
    component: () => import("/vercel/path0/pages/topics/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "topics-maybeIdSlug___es",
    path: "/es/topics/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93Ltuj4XOI1hMeta || {},
    component: () => import("/vercel/path0/pages/topics/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "topics-maybeIdSlug___fr",
    path: "/fr/topics/:maybeIdSlug(.*)*",
    meta: _91_46_46_46maybeIdSlug_93Ltuj4XOI1hMeta || {},
    component: () => import("/vercel/path0/pages/topics/[...maybeIdSlug].vue").then(m => m.default || m)
  },
  {
    name: "topics-idSlug-slug___en",
    path: "/en/topics/:idSlug()/:slug()",
    meta: _91slug_93QpkuWdxVBdMeta || {},
    component: () => import("/vercel/path0/pages/topics/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "topics-idSlug-slug___es",
    path: "/es/topics/:idSlug()/:slug()",
    meta: _91slug_93QpkuWdxVBdMeta || {},
    component: () => import("/vercel/path0/pages/topics/[idSlug]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "topics-idSlug-slug___fr",
    path: "/fr/topics/:idSlug()/:slug()",
    meta: _91slug_93QpkuWdxVBdMeta || {},
    component: () => import("/vercel/path0/pages/topics/[idSlug]/[slug].vue").then(m => m.default || m)
  }
]