import revive_payload_client_0wx9Ub8VRG from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.3_eslint@8.57.0_ioredis@5.4.1_magicast@0._nwpwl5ryqns3cd6ssxpxpfwqte/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lI4GAAwr4Y from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.3_eslint@8.57.0_ioredis@5.4.1_magicast@0._nwpwl5ryqns3cd6ssxpxpfwqte/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NRzluDRcXx from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.3_eslint@8.57.0_ioredis@5.4.1_magicast@0._nwpwl5ryqns3cd6ssxpxpfwqte/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1Y3Q6PsBed from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.3_sass@1.77_t7vpo3pmtmwz7df34jestmt5za/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ZfevljolJn from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.3_eslint@8.57.0_ioredis@5.4.1_magicast@0._nwpwl5ryqns3cd6ssxpxpfwqte/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ntQLqecdef from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.3_eslint@8.57.0_ioredis@5.4.1_magicast@0._nwpwl5ryqns3cd6ssxpxpfwqte/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_3yacsgu606 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.3_eslint@8.57.0_ioredis@5.4.1_magicast@0._nwpwl5ryqns3cd6ssxpxpfwqte/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_xAOMHYe1I4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.16.3_eslint@8.57.0_ioredis@5.4.1_magicast@0._nwpwl5ryqns3cd6ssxpxpfwqte/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_pp46ycWqmR from "/vercel/path0/node_modules/.pnpm/nuxt-viewport@2.1.6_magicast@0.3.5_rollup@4.21.2_vue@3.5.0_typescript@5.5.4_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_cNJXEgaJit from "/vercel/path0/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_@vue+server-renderer@3.5.0_vue@3.5.0_typescript@5.5.4___magicast@0.3.5_sbsrxym5swleniddomyiw5biby/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_aFUa7pSuZY from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node_d3eeh5atbjwacjfu5dy5znj4lm/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_IOL8582NGe from "/vercel/path0/node_modules/.pnpm/mixpanel-nuxt@0.0.13_magicast@0.3.5_rollup@4.21.2/node_modules/mixpanel-nuxt/dist/runtime/plugin.js";
import plugin_N67rTRygQW from "/vercel/path0/node_modules/.pnpm/@hebilicious+authjs-nuxt@0.3.5_@auth+core@0.17.0_magicast@0.3.5_nuxt@3.13.0_@parcel+watcher@2_fpcgp6to6ao6exussg6aq3ikay/node_modules/@hebilicious/authjs-nuxt/dist/runtime/plugin.mjs";
import plugin_O3bKNwAELI from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@types+node@20.16.3_@vue+test-utils@2.4.6_h3@1.12._gqtu6cthbuoczirhqiz4ps6paa/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_RnkGRZ9NfI from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.0_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_VPWQT1XG09 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.0_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import sanity_image_builder_QjxLZCdLua from "/vercel/path0/plugins/sanity-image-builder.ts";
import search_insights_client_UfHujSr26a from "/vercel/path0/plugins/search-insights.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import vue_youtube_client_FWftQfcsyu from "/vercel/path0/plugins/vue-youtube.client.ts";
export default [
  revive_payload_client_0wx9Ub8VRG,
  unhead_lI4GAAwr4Y,
  router_NRzluDRcXx,
  _0_siteConfig_1Y3Q6PsBed,
  payload_client_ZfevljolJn,
  navigation_repaint_client_ntQLqecdef,
  chunk_reload_client_3yacsgu606,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xAOMHYe1I4,
  plugin_client_pp46ycWqmR,
  plugin_cNJXEgaJit,
  plugin_aFUa7pSuZY,
  plugin_IOL8582NGe,
  plugin_N67rTRygQW,
  plugin_O3bKNwAELI,
  switch_locale_path_ssr_RnkGRZ9NfI,
  i18n_VPWQT1XG09,
  posthog_client_fUucxKWhZE,
  sanity_image_builder_QjxLZCdLua,
  search_insights_client_UfHujSr26a,
  sentry_client_shVUlIjFLk,
  vue_youtube_client_FWftQfcsyu
]