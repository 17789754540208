export function getValueOrFirstItem<T>(valueOrItems: T | null | (T | null)[], defaultValue: T): T;
export function getValueOrFirstItem<T>(valueOrItems: T | null | (T | null)[]): T | undefined;

export function getValueOrFirstItem<T>(valueOrItems: T | null | T[], defaultValue?: T): T | undefined {
  const value = Array.isArray(valueOrItems) ? getFirstItem<T>(valueOrItems) : (valueOrItems as T);
  return !value && typeof defaultValue !== 'undefined' ? defaultValue : value;
}

function getFirstItem<T>(items: T[]): T | undefined {
  return items[0];
}
