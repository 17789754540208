<script setup lang="ts">
const localeHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});

useHead(localeHead);
useAppSeoMeta();

const { status, user } = useAuth();
const { $posthog } = useNuxtApp();

watch(
  status,
  (newValue) => {
    // Skip identification check if on server
    if (import.meta.server) return;

    const posthog = $posthog();

    if (newValue === 'authenticated') {
      if (user.value) {
        posthog?.identify(user.value.id, {
          auth0_id: user.value.id,
          auth0_permissions: user.value.permissions,
        });
      }
    }

    if (newValue === 'unauthenticated') {
      posthog?.reset();
    }
  },
  { immediate: true }
);
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
