// aa is Algolia's conventional notation for Algolia Analytics
import aa from 'search-insights';

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();
  const { user } = useAuth();

  aa('init', {
    appId: runtimeConfig.public.algolia.applicationId,
    apiKey: runtimeConfig.public.algolia.apiKey,
  });

  // NOTE: Since user IDs were _not_ always tracked, we need to accommodate users being signed in, but
  // without a user ID
  if (user.value && user.value.id) {
    // NOTE: User IDs cannot contain '|' characters in Algolia
    aa('setAuthenticatedUserToken', user.value.id.replaceAll('|', '_'));
  }

  return {
    provide: {
      insightsClient: aa,
    },
  };
});
