import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup(nuxtApp) {
    const {
      public: { sentry },
    } = useRuntimeConfig();

    if (sentry.disable || !sentry.dsn) {
      return;
    }

    const router = useRouter();

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: [Sentry.browserTracingIntegration({ router, enableInp: true })],

      tracesSampleRate: sentry.tracesSampleRate,
      replaysSessionSampleRate: sentry.replaysSessionSampleRate,
      replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate,
    });

    nuxtApp.hook('app:mounted', async () => {
      // Lazy-loading Session Replay package, as it can be quite heavy
      //    https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/#lazy-loading-replay
      const { replayIntegration } = await import('@sentry/vue');
      Sentry.addIntegration(replayIntegration());
    });

    return {
      provide: {
        sentry: Sentry,
      },
    };
  },
});
