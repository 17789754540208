export default defineAppConfig({
  animations: {
    showDownloadIconDurationMs: 6000,
  },
  legacy: {
    resourceTypes: [
      // Legacy types from { category: 'Simple' } reosurces
      'activity-instructions',
      'agenda',
      'case-study',
      'case-studies', // Plural found to be attached to resources in production data
      'evaluation',
      'evaluation-summary',
      'fact-sheet',
      'fact-sheets', // Plural found to be attached to resources in production data
      'form',
      'guide',
      'handout',
      'instructions',
      'lesson-plan',
      'lesson-plans', // Plural found to be attached to resources in production data
      'literature-summary',
      'participant-list',
      'poster',
      'presentation',
      'product-sheet',
      'questionnaire',
      'section',
      'technical-brief',
      'technical-briefs', // Plural found to be attached to resources in production data
      'tool',
      'trainer-essential',
      'video',
      'webinar-video',
      'workbook',
      'worksheet',
      'workshop-evaluation',
      'workshop-evaluation-summary',
      'workshop-outline',
      'workshop-report',

      // Legacy types from { category: null } reosurces
      'section',

      // Legacy types from { category: 'Complex' }
      'activity',
      'appendix',
      'construction-manual',
      'manual',
      'trainer-manual',
      'forms',
      'lesson-plans',
      'poster-set',
      'trainer-essentials',
    ],
    collectionTypes: [
      // Legacy types from { category: 'Collection' } reosurces
      'training-toolkit',

      // Legacy types from live Sentry data
      'trainer-collection',
    ],
  },
  mixpanel: {
    agent: 'WASH Resources Site',
    source: 'washresources',
  },
  resources: {
    video: {
      preferredProvider: 'vimeo',
    },
  },
  seo: {
    defaultImageUrl: 'https://res.cloudinary.com/cawst/image/upload/v1604601079/wash-resources/wash-rez-banner.jpg',
    featuredImageSize: {
      width: 1200,
      height: 675,
    },
    thumbnailImageSize: {
      width: 512,
      height: 512,
    },
  },
  eLearning: {
    tracking: {
      endpoint: '/api/module-tracking',
      token: 'none',
    },
  },
  typeform: {
    feedbackForm: {
      id: 'odDqOL',
    },
    versionForm: {
      idTKey: 'typeform.versionForm.id',
    },
  },
});
