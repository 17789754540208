export type AppSeoPreviewType = 'feature' | 'thumbnail';

type UseAppSeoMetaOptions = {
  title?: Ref<string | undefined> | string;
  description?: Ref<string | undefined> | string;
  imageUrl?: Ref<string | undefined> | string;
  previewType?: Ref<AppSeoPreviewType | undefined> | AppSeoPreviewType;
};

export function useAppSeoMeta(options: UseAppSeoMetaOptions = {}) {
  const route = useRoute();
  const { t } = useI18n();
  const appConfig = useAppConfig();

  const title = computed(() => {
    if (route.meta.titleTKey) {
      return `${t(route.meta.titleTKey as string)} | ${t('site.title')}`;
    }

    if (toValue(options.title)) {
      return `${toValue(options.title)} | ${t('site.title')}`;
    }

    return t('site.title');
  });

  const description = computed(() => {
    if (route.meta.descriptionTKey) {
      return t(route.meta.descriptionTKey as string);
    }

    if (toValue(options.description)) {
      return toValue(options.description);
    }

    return t('pages.home.description');
  });

  const imageUrl = computed(() => {
    if (toValue(options.imageUrl)) {
      return toValue(options.imageUrl);
    }

    return appConfig.seo.defaultImageUrl;
  });

  const safePreviewType = computed(() => {
    return toValue(options.previewType) || 'feature';
  });

  const twitterCard = computed(() => {
    if (safePreviewType.value === 'thumbnail') {
      return 'summary';
    }

    return 'summary_large_image';
  });

  const ogImageWidth = computed(() => {
    if (safePreviewType.value === 'thumbnail') {
      return appConfig.seo.thumbnailImageSize.width;
    }

    return appConfig.seo.featuredImageSize.width;
  });

  const ogImageHeight = computed(() => {
    if (safePreviewType.value === 'thumbnail') {
      return appConfig.seo.thumbnailImageSize.height;
    }

    return appConfig.seo.featuredImageSize.height;
  });

  useSeoMeta({
    // Title attributes
    title,
    ogTitle: title,
    twitterTitle: title,

    // Description attributes
    description,
    ogDescription: description,
    twitterDescription: description,

    // Image and Twitter Card data
    twitterCard,
    ogImage: imageUrl,
    twitterImage: imageUrl,
    ogImageWidth,
    ogImageHeight,
  });
}
