// eslint-disable-next-line import/no-named-as-default
import posthog from 'posthog-js';

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();
  const posthogClient = posthog.init(runtimeConfig.public.posthog.publicKey, {
    api_host: runtimeConfig.public.posthog.apiHost,
    ui_host: runtimeConfig.public.posthog.uiHost,
    person_profiles: 'identified_only',
    capture_pageview: false,
  });

  const previousRoutePath = ref('');

  const router = useRouter();
  router.beforeEach((to, _from, next) => {
    previousRoutePath.value = to.fullPath;
    next();
  });

  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath,
      });
    });
  });

  watch(previousRoutePath, (_newValue, oldValue) => {
    if (!oldValue) return;
    posthog.capture('$pageleave');
  });

  return {
    provide: {
      posthog: () => posthogClient,
    },
  };
});
