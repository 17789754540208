import imageUrlBuilder from '@sanity/image-url';
import type { SanityImageSource } from '@sanity/image-url/lib/types/types';

export default defineNuxtPlugin(() => {
  const sanity = useSanity();
  const builder = imageUrlBuilder({
    projectId: sanity.config.projectId || '',
    dataset: sanity.config.dataset || 'development',
  });
  function urlFor(source: SanityImageSource) {
    return builder.image(source).auto('format');
  }
  return {
    provide: {
      urlFor,
    },
  };
});
